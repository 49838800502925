import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { getPropFunnels } from '../../../services/BackendService';
import _t from 'counterpart';
import BooleanBadge from '../../../components/BooleanBadge';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import PageLayout from '../../../components/PageLayout';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CLink, CRow } from '@coreui/react';
import PaginationTable from '../../../components/PaginationTable';
import { formatCurrency } from '../../../helpers';
import { IPropFunnel } from './types';

const PropFunnelsPage = () => {
	const history = useHistory();

	const propFunnelsQuery = useQuery<Array<IPropFunnel>>(['prop-funnels'], () => getPropFunnels());

	const tableFields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'title', label: _t('prop-funnels.title'), sorter: false },
			{ key: 'feeInUSD', label: _t('prop-funnels.fee-in-usd'), sorter: false },
			{ key: 'enabled', label: _t('prop-funnels.enabled'), sorter: false },
			{ key: 'fundedAccountTypeId', label: _t('prop-funnels.funded-account-type-id'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			id: ({ id }: IPropFunnel) => <td>{id || '-'}</td>,
			title: ({ title }: IPropFunnel) => <td>{title || '-'}</td>,
			feeInUSD: ({ feeInUSD }: IPropFunnel) => <td>{formatCurrency(feeInUSD) || '-'}</td>,
			enabled: ({ enabled }: IPropFunnel) => (
				<td>
					<BooleanBadge value={enabled} />
				</td>
			),
			fundedAccountTypeId: ({ fundedAccountType }: IPropFunnel) => <td>{fundedAccountType.title}</td>,
		}),
		[]
	);

	const rowClicked = useCallback(
		(propFunnel: IPropFunnel) => {
			const { id } = propFunnel;
			history.push(`/prop-funnels/${id}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history.push]
	);

	const linkToCreatePage = () => {
		history.push('/prop-funnels/form');
	};

	const onErrorRetry = () => {
		propFunnelsQuery.refetch();
	};

	if (propFunnelsQuery.isIdle) {
		return <Loading />;
	}

	if (propFunnelsQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('prop-funnels.funnels')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={linkToCreatePage} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={propFunnelsQuery.data || []}
								loading={propFunnelsQuery.isLoading}
								pages={0}
								pagination
								onRowClicked={rowClicked}
								clickableRows
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default PropFunnelsPage;
