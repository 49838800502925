import React, { useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CButton,
	CModal,
	CModalHeader,
	CModalTitle,
	CModalFooter,
	CLink,
} from '@coreui/react';
import Loading from '../../../components/Loading';
import { formatCurrency, extractErrorMessage } from '../../../helpers';
import _t from 'counterpart';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deletePropFunnel, getPropFunnel } from '../../../services/BackendService';
import { useAppSelector } from '../../../helpers/customHooks';
import PageLayout from '../../../components/PageLayout';
import { Permission } from '../../../reducers/userReducer';
import Description from '../../../components/Description';
import toast from 'react-hot-toast';
import BooleanBadge from '../../../components/BooleanBadge';
import { IPropFunnel } from './types';

const PropFunnelPage = () => {
	const [deleteFunnelModalShow, deleteFunnelModalToggle] = useState<boolean>(false);

	const permissions = useAppSelector((state) => state.user.permissions);

	const params = useParams();
	const history = useHistory();

	const { id } = params as any;

	const { data, isLoading } = useQuery<IPropFunnel>(['prop-funnel', id], () => getPropFunnel(id), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const queryClient = useQueryClient();

	const deletePropFunnelMutation = useMutation((id: string) => deletePropFunnel(id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('prop-funnels');
			deleteFunnelModalToggle(false);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	if (isLoading || !data) {
		return <Loading />;
	}

	const { title, feeInUSD, enabled, firstChallenge, secondChallenge, thirdChallenge, fundedAccountType } = data!;
	const deletePropFunnelConfirmed = () => {
		deletePropFunnelMutation.mutate(id);
		history.push(`/prop-funnels`);
	};

	const onEditClicked = () => {
		history.push(`/prop-funnels/form?edit=${id}`);
	};

	const hasEditPermissions = permissions?.includes(Permission.MANAGE_PROP_FUNNELS);

	return (
		<PageLayout
			title={_t.translate('prop-funnels.funnel') + ` #${id}`}
			titleAppend={
				<>
					{hasEditPermissions && (
						<div className="d-flex justify-content-between float-right mb-2">
							<div className="d-flex flex-nowrap flex-row-reverse">
								{
									<CButton color="primary" onClick={onEditClicked}>
										{_t('action.edit')}
									</CButton>
								}
								{
									<CButton color="danger" className="mr-2" onClick={() => deleteFunnelModalToggle(true)}>
										{_t('action.delete')}
									</CButton>
								}
							</div>
						</div>
					)}
				</>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('prop-funnels.id')}>{id}</Description>
								<Description title={_t('prop-funnels.title')}>{title}</Description>
							</CRow>
							<CRow>
								<Description title={`${_t('prop-funnels.fee-in-usd')}`}>{formatCurrency(feeInUSD)}</Description>
								<Description title={_t('prop-funnels.enabled')}>
									<BooleanBadge value={enabled} />
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('prop-funnels.first-challenge-type-id')}>
									{firstChallenge ? (
										<CLink to={`/prop-challenges/${firstChallenge.id}`}>{firstChallenge.title}</CLink>
									) : (
										'-'
									)}
								</Description>
								<Description title={_t('prop-funnels.second-challenge-type-id')}>
									{secondChallenge ? (
										<CLink to={`/prop-challenges/${secondChallenge.id}`}>{secondChallenge.title}</CLink>
									) : (
										'-'
									)}
								</Description>
								<Description title={_t('prop-funnels.third-challenge-type-id')}>
									{thirdChallenge ? (
										<CLink to={`/prop-challenges/${thirdChallenge.id}`}>{thirdChallenge.title}</CLink>
									) : (
										'-'
									)}
								</Description>
								<Description title={_t('prop-funnels.funded-account-type-id')}>
									{fundedAccountType ? (
										<CLink to={`/funded-account-types/${fundedAccountType.id}`}>{fundedAccountType.title}</CLink>
									) : (
										'-'
									)}
								</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CModal show={deleteFunnelModalShow} onClose={() => deleteFunnelModalToggle(false)}>
				<CModalHeader>
					<CModalTitle>{_t('prop-funnels.delete-modal-title')}</CModalTitle>
				</CModalHeader>
				{permissions?.includes(Permission.MANAGE_PROP_FUNNELS) && (
					<CModalFooter className="modal-footer--alternative">
						<CButton color="secondary" onClick={() => deleteFunnelModalToggle(false)}>
							{_t('action.cancel')}
						</CButton>
						<CButton color="danger" onClick={deletePropFunnelConfirmed}>
							{_t('prop-funnels.delete')}
						</CButton>
					</CModalFooter>
				)}
			</CModal>
		</PageLayout>
	);
};

export default PropFunnelPage;
