import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CCard, CCardBody, CRow, CCol, CButton, CModal, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import Loading from '../../../components/Loading';
import { formatCurrency, extractErrorMessage } from '../../../helpers';
import _t from 'counterpart';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteFundedAccountType, getFundedAccountType } from '../../../services/BackendService';
import { useAppSelector } from '../../../helpers/customHooks';
import PageLayout from '../../../components/PageLayout';
import { Permission } from '../../../reducers/userReducer';
import Description from '../../../components/Description';
import toast from 'react-hot-toast';
import BooleanBadge from '../../../components/BooleanBadge';
import { IFundedAccountType } from './types';

const FundedAccountTypePage = () => {
	const [deleteFundedAccountModalShow, deleteFundedAccountModalToggle] = useState<boolean>(false);

	const permissions = useAppSelector((state) => state.user.permissions);

	const params = useParams();
	const history = useHistory();

	const { id } = params as any;

	const { data, isLoading } = useQuery<IFundedAccountType>(['funded-account', id], () => getFundedAccountType(id), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const queryClient = useQueryClient();

	const deleteFundedAccountMutation = useMutation((id: number) => deleteFundedAccountType(id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('funded-accounts');
			deleteFundedAccountModalToggle(false);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	if (isLoading || !data) {
		return <Loading />;
	}

	const {
		title,
		leverage,
		initialBalance,
		minTradingDays,
		maxInactivityDays,
		maxDailyDrawdownFactor,
		maxTotalDrawdownFactor,
		traderProfitShareFactor,
		isDemo,
		accountGroup,
		accountCurrency,
	} = data!;
	const deleteFundedAccountConfirmed = () => {
		deleteFundedAccountMutation.mutate(id);
		history.push(`/funded-account-types`);
	};

	const onEditClicked = () => {
		history.push(`/funded-account-types/form?edit=${id}`);
	};

	const hasEditPermissions = permissions?.includes(Permission.MANAGE_FUNDED_ACCOUNT_TYPES);

	return (
		<PageLayout
			title={_t.translate('funded-account-types.account-type') + ` #${id}`}
			titleAppend={
				<>
					{hasEditPermissions && (
						<div className="d-flex justify-content-between float-right mb-2">
							<div className="d-flex flex-nowrap flex-row-reverse">
								{
									<CButton color="primary" onClick={onEditClicked}>
										{_t('action.edit')}
									</CButton>
								}
								{
									<CButton color="danger" className="mr-2" onClick={() => deleteFundedAccountModalToggle(true)}>
										{_t('action.delete')}
									</CButton>
								}
							</div>
						</div>
					)}
				</>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('funded-account-types.id')}>{id}</Description>
								<Description title={_t('funded-account-types.title')}>{title}</Description>
							</CRow>
							<CRow>
								<Description title={`${_t('funded-account-types.initial-balance')}`}>
									{formatCurrency(initialBalance, accountCurrency)}
								</Description>
								<Description title={_t('funded-account-types.leverage')}>{leverage}</Description>
							</CRow>
							<CRow>
								<Description title={_t('funded-account-types.min-trading-days')}>{minTradingDays}</Description>
								<Description title={_t('funded-account-types.max-inactivity-days')}>{maxInactivityDays}</Description>
							</CRow>
							<CRow>
								<Description title={_t('funded-account-types.max-daily-drawdown')}>
									{Number(maxDailyDrawdownFactor) * 100}%
								</Description>

								<Description title={_t('funded-account-types.max-total-drawdown')}>
									{Number(maxTotalDrawdownFactor) * 100}%
								</Description>

								<Description title={_t('funded-account-types.trader-profit-share-factor')}>
									{Number(traderProfitShareFactor) * 100}%
								</Description>
								<Description title={_t('funded-account-types.account-currency')}>{accountCurrency}</Description>
							</CRow>
							<CRow>
								<Description title={_t('funded-account-types.account-group')}>{accountGroup}</Description>
								<Description title={_t('funded-account-types.is-demo')}>
									<BooleanBadge value={isDemo} />
								</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CModal show={deleteFundedAccountModalShow} onClose={() => deleteFundedAccountModalToggle(false)}>
				<CModalHeader>
					<CModalTitle>{_t('funded-account-types.delete-modal-title')}</CModalTitle>
				</CModalHeader>
				{permissions?.includes(Permission.MANAGE_FUNDED_ACCOUNT_TYPES) && (
					<CModalFooter className="modal-footer--alternative">
						<CButton color="secondary" onClick={() => deleteFundedAccountModalToggle(false)}>
							{_t('action.cancel')}
						</CButton>
						<CButton color="danger" onClick={deleteFundedAccountConfirmed}>
							{_t('funded-account-types.delete')}
						</CButton>
					</CModalFooter>
				)}
			</CModal>
		</PageLayout>
	);
};

export default FundedAccountTypePage;
