import React, { useState, useEffect, FormEvent } from 'react';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CForm,
	CFormGroup,
	CLabel,
	CInputGroup,
	CInput,
	CSwitch,
	CInputGroupPrepend,
	CInputGroupText,
} from '@coreui/react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import IPropChallenge, { ICreatePropChallengeFormData, IUpdatePropChallengeFormData } from './types';
import {
	updatePropTradingChallenge,
	createPropTradingChallenge,
	getPropTradingChallenge,
} from '../../services/BackendService';
import { findErrorFromValidation, errorWithCode } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import PageLayout from '../../components/PageLayout';
import toast from 'react-hot-toast';
import { ChallengeGroupsSelector } from './helperComponents';

const CreateUpdatePropChallengePage = () => {
	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');

	const isEditing = editParam !== null;
	const challengeId = editParam ? decodeURIComponent(editParam!) : null;
	const [formState, setFormState] = useState<ICreatePropChallengeFormData | IUpdatePropChallengeFormData>({
		title: undefined,
		leverage: undefined,
		initialBalance: undefined,
		minTradingDays: undefined,
		maxTradingDays: undefined,
		maxInactivityDays: undefined,
		profitTargetFactor: undefined,
		maxDailyDrawdownFactor: undefined,
		maxTotalDrawdownFactor: undefined,
		isDemoAccount: false,
		accountGroup: undefined,
	});

	const onError = (error: any) => {
		if (error) {
			const { message, code } = errorWithCode(error);

			if (code !== 422) {
				toast.error(message);
			}
		}
	};

	const createChallengeMutation = useMutation(
		(challenge: ICreatePropChallengeFormData) => createPropTradingChallenge(challenge),
		{
			onSuccess: () => {
				toast.success(_t('prop-challenges.challenge-created-successfully'));
				history.push('/prop-challenges');
			},
			onError,
			retry: false,
		}
	);

	const updateChallengeMutation = useMutation(
		(challenge: IUpdatePropChallengeFormData) => updatePropTradingChallenge(challenge, challengeId!),
		{
			onSuccess: () => {
				toast.success(_t('prop-challenges.challenge-updated-successfully'));
				history.push('/prop-challenges');
			},
			onError,
			retry: false,
		}
	);

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(createChallengeMutation.error || updateChallengeMutation.error, paramName);
		return error;
	};

	const { data: propChallenge } = useQuery<IPropChallenge | null, Error>(
		[`prop-challenge-${challengeId ?? 'unknown'}`],
		async () => {
			if (challengeId) {
				const data = await getPropTradingChallenge(challengeId);
				return data;
			}
			return null;
		},
		{
			onError,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (propChallenge) {
			setFormState({ ...propChallenge });
		}
	}, [propChallenge]);

	const createOrUpdatePropChallenge = (e: React.FormEvent) => {
		e.preventDefault();
		if (isEditing) {
			const mutationData = {
				...formState,
				leverage: Number(formState.leverage),
				initialBalance: formState.initialBalance,
				minTradingDays: Number(formState.minTradingDays),
				maxTradingDays: Number(formState.maxTradingDays),
				maxInactivityDays: Number(formState.maxInactivityDays),
				profitTarget: formState.profitTargetFactor,
				maxDailyDrawdown: formState.maxDailyDrawdownFactor,
				maxTotalDrawdown: formState.maxTotalDrawdownFactor,
			};
			updateChallengeMutation.mutate(mutationData);
		} else {
			const mutationData = {
				...(formState as ICreatePropChallengeFormData),
				leverage: Number(formState.leverage),
				initialBalance: formState.initialBalance!,
				minTradingDays: Number(formState.minTradingDays),
				maxTradingDays: Number(formState.maxTradingDays),
				maxInactivityDays: Number(formState.maxInactivityDays),
				profitTarget: formState.profitTargetFactor!,
				maxDailyDrawdown: formState.maxDailyDrawdownFactor!,
				maxTotalDrawdown: formState.maxTotalDrawdownFactor!,
			};
			createChallengeMutation.mutate(mutationData);
		}
	};

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setFormState({
			...formState,
			[name]: value,
		});
	};

	const onSelectChange = (newValue: any) => {
		if (newValue !== formState.accountGroup) {
			setFormState({ ...formState, accountGroup: newValue });
		}
	};

	const toggleIsDemoAccount = () => {
		setFormState({ ...formState, isDemoAccount: !formState.isDemoAccount });
	};

	const loading = createChallengeMutation.isLoading || updateChallengeMutation.isLoading;
	const getTitle = () => {
		if (!challengeId) {
			return _t.translate('prop-challenges.create-challenge');
		}
		return _t.translate('prop-challenges.edit-challenge');
	};

	const title = getTitle();

	return (
		<PageLayout title={title}>
			<CForm onSubmit={createOrUpdatePropChallenge}>
				<CRow>
					<CCol>
						<CCard>
							<CCardBody>
								<CFormGroup>
									<CLabel htmlFor="title">{_t(`prop-challenges.title`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											id={'title'}
											name={'title'}
											placeholder={'First VIP Challenge'}
											value={formState.title || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>

								<CFormGroup>
									<CLabel htmlFor="initial-balance">{_t(`prop-challenges.initial-balance`)}</CLabel>

									<CInputGroup>
										<CInputGroupPrepend>
											<CInputGroupText>{'$'}</CInputGroupText>
										</CInputGroupPrepend>
										<CInput
											type="number"
											id="initial-balance"
											name="initialBalance"
											placeholder="200"
											value={formState.initialBalance || ''}
											onChange={handleInputChange}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('initialBalance') && (
										<CLabel className="text-danger">{findError('initialBalance')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="leverage">{_t(`prop-challenges.leverage`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'leverage'}
											name={'leverage'}
											placeholder={'1'}
											value={formState.leverage || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('leverage') && <CLabel className="text-danger">{findError('leverage')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="minimal-trading-days">{_t(`prop-challenges.min-trading-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'minimal-trading-days'}
											name={'minTradingDays'}
											placeholder={'1'}
											value={formState.minTradingDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('minTradingDays') && (
										<CLabel className="text-danger">{findError('minTradingDays')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-trading-days">{_t(`prop-challenges.max-trading-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-trading-days'}
											name={'maxTradingDays'}
											placeholder={'5'}
											value={formState.maxTradingDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxTradingDays') && (
										<CLabel className="text-danger">{findError('maxTradingDays')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-inactivity-days">{_t(`prop-challenges.max-inactivity-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-inactivity-days'}
											name={'maxInactivityDays'}
											placeholder={'1'}
											value={formState.maxInactivityDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxInactivityDays') && (
										<CLabel className="text-danger">{findError('maxInactivityDays')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="profit-target">{_t(`prop-challenges.profit-target-factor`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id="profit-target"
											name="profitTargetFactor"
											placeholder="0.07"
											value={formState.profitTargetFactor || ''}
											onChange={handleInputChange}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('profitTarget') && (
										<CLabel className="text-danger">{findError('profitTargetFactor')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="max-daily-drawdown">{_t(`prop-challenges.max-daily-drawdown`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'max-daily-drawdown'}
											name={'maxDailyDrawdownFactor'}
											placeholder={'0.1'}
											value={formState.maxDailyDrawdownFactor || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxDailyDrawdown') && (
										<CLabel className="text-danger">{findError('maxDailyDrawdownFactor')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="max-total-drawdown">{_t(`prop-challenges.max-total-drawdown`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'max-total-drawdown'}
											name={'maxTotalDrawdownFactor'}
											placeholder={'0.2'}
											value={formState.maxTotalDrawdownFactor || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('maxTotalDrawdown') && (
										<CLabel className="text-danger">{findError('maxTotalDrawdownFactor')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-group">{_t('prop-challenges.account-group')}</CLabel>
									<ChallengeGroupsSelector
										value={formState.accountGroup || ''}
										onChange={onSelectChange}
										currency={'USD'}
									/>
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t(`prop-challenges.is-demo-account`)}</CLabel>
									<br></br>
									<CSwitch
										size="lg"
										shape="pill"
										color="primary"
										checked={formState.isDemoAccount}
										onChange={toggleIsDemoAccount}
									/>
									<br></br>
									{findError('is-demo-account') && (
										<CLabel className="text-danger">{findError('is-demo-account')}</CLabel>
									)}
								</CFormGroup>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isEditing ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default CreateUpdatePropChallengePage;
