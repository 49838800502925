import React, { useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { CCard, CCardBody, CRow, CCol, CButton, CModal, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import Loading from '../../components/Loading';
import { formatCurrency, extractErrorMessage } from '../../helpers';
import _t from 'counterpart';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deletePropTradingChallenge, getPropTradingChallenge } from '../../services/BackendService';
import { useAppSelector } from '../../helpers/customHooks';
import PageLayout from '../../components/PageLayout';
import { Permission } from '../../reducers/userReducer';
import Description from '../../components/Description';
import toast from 'react-hot-toast';
import { IPropChallengeWithProgress } from './types';
import BooleanBadge from '../../components/BooleanBadge';

const PropChallengePage = () => {
	const [deleteChallengeModalShow, deleteChallengeModalToggle] = useState<boolean>(false);

	const permissions = useAppSelector((state) => state.user.permissions);

	const params = useParams();
	const history = useHistory();

	const { id } = params as any;

	const { data, isLoading } = useQuery<IPropChallengeWithProgress>(
		['prop-challenge', id],
		() => getPropTradingChallenge(id),
		{
			onError: (e: any) => {
				const error = extractErrorMessage(e);
				toast.error(error);
			},
			retry: false,
		}
	);

	const queryClient = useQueryClient();

	const deletePropChallengeMutation = useMutation((id: string) => deletePropTradingChallenge(id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('prop-challenges');
			deleteChallengeModalToggle(false);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	if (isLoading || !data) {
		return <Loading />;
	}

	const {
		title,
		leverage,
		initialBalance,
		minTradingDays,
		maxTradingDays,
		maxInactivityDays,
		profitTargetFactor,
		maxDailyDrawdownFactor,
		maxTotalDrawdownFactor,
		isDemoAccount,
		accountGroup,
		hasActiveProgress,
	} = data!;
	const deletePropChallengeConfirmed = () => {
		deletePropChallengeMutation.mutate(id);
		history.push(`/prop-challenges`);
	};

	const renderNextChallengeId = (challengeId: string | null) => {
		if (challengeId) {
			return <Link to={`/prop-challenges/${challengeId}`}>{challengeId}</Link>;
		}
		return challengeId;
	};

	const onEditClicked = () => {
		history.push(`/prop-challenges/form?edit=${id}`);
	};

	const hasEditPermissions = permissions?.includes(Permission.MANAGE_PROP_CHALLENGES);

	return (
		<PageLayout
			title={_t.translate('prop-challenges.challenge') + ` #${id}`}
			titleAppend={
				<>
					{hasEditPermissions && !hasActiveProgress && (
						<div className="d-flex justify-content-between float-right mb-2">
							<div className="d-flex flex-nowrap flex-row-reverse">
								{
									<CButton color="primary" onClick={onEditClicked}>
										{_t('action.edit')}
									</CButton>
								}
								{
									<CButton color="danger" className="mr-2" onClick={() => deleteChallengeModalToggle(true)}>
										{_t('action.delete')}
									</CButton>
								}
							</div>
						</div>
					)}
				</>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('prop-challenges.id')}>{id}</Description>
								<Description title={_t('prop-challenges.title')}>{title}</Description>
							</CRow>
							<CRow>
								<Description title={_t('prop-challenges.profit-target-factor')}>
									{Number(profitTargetFactor) * 100}%
								</Description>
								<Description title={_t('prop-challenges.leverage')}>{leverage}</Description>
							</CRow>
							<CRow>
								<Description title={`${_t('prop-challenges.initial-balance')}`}>
									{formatCurrency(initialBalance)}
								</Description>
								<Description title={_t('prop-challenges.min-trading-days')}>{minTradingDays}</Description>
								<Description title={_t('prop-challenges.max-trading-days')}>{maxTradingDays}</Description>
								<Description title={_t('prop-challenges.max-inactivity-days')}>{maxInactivityDays}</Description>
							</CRow>
							<CRow>
								<Description title={_t('prop-challenges.max-daily-drawdown')}>
									{Number(maxDailyDrawdownFactor) * 100}%
								</Description>

								<Description title={_t('prop-challenges.max-total-drawdown')}>
									{Number(maxTotalDrawdownFactor) * 100}%
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('prop-challenges.account-group')}>{accountGroup}</Description>
								<Description title={_t('prop-challenges.is-demo-account')}>
									<BooleanBadge value={isDemoAccount} />
								</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CModal show={deleteChallengeModalShow} onClose={() => deleteChallengeModalToggle(false)}>
				<CModalHeader>
					<CModalTitle>{_t('prop-challenges.delete-modal-title')}</CModalTitle>
				</CModalHeader>
				{permissions?.includes(Permission.MANAGE_PROP_CHALLENGES) && (
					<CModalFooter className="modal-footer--alternative">
						<CButton color="secondary" onClick={() => deleteChallengeModalToggle(false)}>
							{_t('action.cancel')}
						</CButton>
						<CButton color="danger" onClick={deletePropChallengeConfirmed}>
							{_t('prop-challenges.delete')}
						</CButton>
					</CModalFooter>
				)}
			</CModal>
		</PageLayout>
	);
};

export default PropChallengePage;
