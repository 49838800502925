export const cilChart1 = [
	'40 40',
	`
<svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1995_1754)">
<path d="M4.84375 5.3125H1.40625C1.19912 5.31274 1.00055 5.39512 0.854085 5.54159C0.707624 5.68805 0.625238 5.88662 0.625 6.09375V18.5938C0.625238 18.8009 0.707624 18.9995 0.854085 19.1459C1.00055 19.2924 1.19912 19.3748 1.40625 19.375H4.84375C5.05088 19.3748 5.24945 19.2924 5.39591 19.1459C5.54238 18.9995 5.62476 18.8009 5.625 18.5938V6.09375C5.62476 5.88662 5.54238 5.68805 5.39591 5.54159C5.24945 5.39512 5.05088 5.31274 4.84375 5.3125ZM4.375 18.125H1.875V6.5625H4.375V18.125Z" fill="#414141"/>
<path d="M11.7188 9.375H8.28125C8.07412 9.37524 7.87555 9.45762 7.72909 9.60409C7.58262 9.75055 7.50024 9.94912 7.5 10.1562V18.5938C7.50024 18.8009 7.58262 18.9995 7.72909 19.1459C7.87555 19.2924 8.07412 19.3748 8.28125 19.375H11.7188C11.9259 19.3748 12.1245 19.2924 12.2709 19.1459C12.4174 18.9995 12.4998 18.8009 12.5 18.5938V10.1562C12.4998 9.94912 12.4174 9.75055 12.2709 9.60409C12.1245 9.45762 11.9259 9.37524 11.7188 9.375ZM11.25 18.125H8.75V10.625H11.25V18.125Z" fill="#414141"/>
<path d="M18.5938 0.625H15.1562C14.9491 0.625238 14.7505 0.707624 14.6041 0.854085C14.4576 1.00055 14.3752 1.19912 14.375 1.40625V18.5938C14.3752 18.8009 14.4576 18.9995 14.6041 19.1459C14.7505 19.2924 14.9491 19.3748 15.1562 19.375H18.5938C18.8009 19.3748 18.9995 19.2924 19.1459 19.1459C19.2924 18.9995 19.3748 18.8009 19.375 18.5938V1.40625C19.3748 1.19912 19.2924 1.00055 19.1459 0.854085C18.9995 0.707624 18.8009 0.625238 18.5938 0.625ZM18.125 18.125H15.625V1.875H18.125V18.125Z" fill="#414141"/>
</g>
<defs>
</defs>
</svg> `,
];
