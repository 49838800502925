import React, { useMemo, useState } from 'react';
import _t from 'counterpart';
import { CBadge, CCard, CCardBody, CCardHeader, CCardSubtitle, CCardTitle, CCol, CLink, CRow } from '@coreui/react';
import { useQuery } from 'react-query';
import { getPropAccountProgress, getPropTradingChallenge } from '../../services/BackendService';
import { useParams } from 'react-router-dom';
import { extractErrorMessage, formatCurrency } from '../../helpers';
import PaginationTable from '../../components/PaginationTable';
import Error from '../../components/Error';
import toast from 'react-hot-toast';
import IPropChallenge, { IChallengeLimit, IChallengeObjective } from '../propTrading/types';
import PropChallengeAccountStatusBadge from './PropChallengeAccountStatusBadge';

const PropAccountChallengeInfo = () => {
	const params = useParams();

	const [propChallenge, setPropChallenge] = useState<IPropChallenge | null>(null);

	const { id } = params as any;

	const { data, isLoading, refetch, isError } = useQuery(
		['prop-challenge-account-progress', id],
		() => getPropAccountProgress(id),
		{
			onError: (e: any) => {
				const error = extractErrorMessage(e);
				toast.error(error);
			},
		}
	);

	const showLinkToChallenge = () => {
		return <CLink to={`/prop-challenges/${data?.propChallengeId}`}>{data?.propChallengeId}</CLink>;
	};

	useQuery(['prop-challenge', data?.propChallengeId], () => getPropTradingChallenge(data?.propChallengeId!), {
		onSuccess: (propChallenge) => {
			setPropChallenge(propChallenge);
		},
		enabled: !!data?.propChallengeId,
	});

	const tableFields = useMemo(
		() => [
			{
				key: 'tradingObjective',
				label: _t('prop-challenges.trading-objective'),
				sorter: true,
			},
			{
				key: 'Result',
				label: _t('prop-challenges.result'),
				sorter: false,
			},
			{
				key: 'Summary',
				label: _t('prop-challenges.summary'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			tradingObjective: ({ name }: IChallengeObjective) => <td>{returnTranslatedName(name)}</td>,
			Result: ({ isCurrency, target, current }: IChallengeObjective) => (
				<td>{returnFormattedResult(isCurrency, current, target)}</td>
			),
			Summary: ({ status }: IChallengeObjective) => <PropChallengeAccountStatusBadge status={status} />,
		};
	}, []);

	const onErrorRetry = () => {
		refetch();
	};

	const tableFieldsLimits = useMemo(
		() => [
			{
				key: 'limit',
				label: _t('prop-challenges.limit'),
				sorter: true,
			},
			{
				key: 'Result',
				label: _t('prop-challenges.result'),
				sorter: false,
			},
			{
				key: 'Summary',
				label: _t('prop-challenges.has-been-breached'),
				sorter: false,
			},
		],
		[]
	);

	const returnTranslatedName = (name: string) => {
		switch (name) {
			case 'minTradingDays':
				return _t('prop-challenges.min-trading-days');
			case 'profitTarget':
				return _t('prop-challenges.profit-target-factor');
			case 'maxTradingDays':
				return _t('prop-challenges.max-trading-days');
			case 'maxInactivityDays':
				return _t('prop-challenges.max-inactivity-days');
			case 'maxDailyDrawdown':
				return _t('prop-challenges.max-daily-drawdown');
			case 'maxTotalDrawdown':
				return _t('prop-challenges.max-total-drawdown');
			default:
				return name;
		}
	};

	const returnFormattedResult = (isCurrency: boolean, current: number, max: number) => {
		return isCurrency ? `${formatCurrency(current)}/${formatCurrency(max)}` : `${current}/${max}`;
	};

	const scopedSlotsLimits = useMemo(() => {
		return {
			limit: ({ name }: IChallengeLimit) => <td>{returnTranslatedName(name)}</td>,
			Result: ({ limit, current, isCurrency }: IChallengeLimit) => (
				<td>{returnFormattedResult(isCurrency, current, limit)}</td>
			),
			Summary: ({ isBreached }: IChallengeLimit) => (
				<CBadge color={isBreached ? 'danger' : 'primary'}>{isBreached ? 'YES' : 'NO'}</CBadge>
			),
		};
	}, []);

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<>
			<CRow>
				<CCol lg={6}>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-left">
								<CCardTitle className="float-left mb-1">
									{_t('prop-challenges.trading-objectives-title') + ' #'}
									{data ? showLinkToChallenge() : ''}
								</CCardTitle>
								{data && data.nextChallengeWalletId && (
									<CCardSubtitle>
										{_t('prop-challenges.trading-objectives-subtitle')} {data?.nextChallengeWalletId}
									</CCardSubtitle>
								)}
							</div>
							<div className="float-right">
								<PropChallengeAccountStatusBadge status={data?.status!} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={data?.objectives ?? []}
								loading={isLoading}
								pages={0}
							/>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={6}>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-left">
								<CCardTitle className="float-left mb-1">{_t('prop-challenges.limits')}</CCardTitle>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFieldsLimits}
								scopedSlots={scopedSlotsLimits}
								data={data?.limits ?? []}
								loading={isLoading}
								pages={0}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</>
	);
};

export default PropAccountChallengeInfo;
