import React from 'react';
import { CImg, CSidebar, CSidebarBrand, CSidebarNav, CSidebarNavDropdown, CSidebarNavItem } from '@coreui/react';
import _t from 'counterpart';
import { capitalize } from '../helpers';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../images/logo';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import {
	clearPositionsFilters,
	clearDepositsFilters,
	clearWithdrawalFilters,
	clearCustomersFilters,
	setSidebarShown,
	clearTransactionsFilters,
} from '../actions';
import { Permission } from '../reducers/userReducer';
import { useGetSetting } from '../pages/generalSettings/hooks';
import { cilGlobe1 } from '../icons/cil-globe-1';
import { cilWallpaper1 } from '../icons/cil-wallpaper-1';
import { cilClock1 } from '../icons/cil-clock-1';
import { cilCash1 } from '../icons/cil-cash-1';
import { cilChartline1 } from '../icons/cil-chartline-1';
import { cilBalancescale1 } from '../icons/cil-balancescale-1';
import { cilExchangeRates1 } from '../icons/cil-exchange-rates-1';
import { cilCurrencyConversion1 } from '../icons/cil-currency-conversion-1';

const MainNavigation = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const permissions = useAppSelector((state) => state.user.permissions);

	const displaySettingsArray = [
		Permission.VIEW_INSTRUMENTS,
		Permission.VIEW_GROUPS,
		Permission.VIEW_EMAILS,
		Permission.VIEW_CUSTOMER_FIELD_CONFIGURATIONS,
		Permission.VIEW_BONUSES,
		Permission.VIEW_COUNTRIES,
		Permission.VIEW_BANNERS,
		Permission.VIEW_DOWNTIMES,
		Permission.VIEW_PAYMENT_PROVIDERS,
		Permission.MANAGE_MAX_RISK,
		Permission.MANAGE_CALL_PROVIDERS,
		Permission.VIEW_EXCHANGE_RATE,
		Permission.MANAGE_SALES_STATUS,
		Permission.MANAGE_CURRENCY_CONVERSIONS,
		Permission.MANAGE_GENERAL_SETTINGS,
		Permission.VIEW_ADAPTERS,
	];

	const displayCustomersBarArray = [Permission.VIEW_CUSTOMERS, Permission.VIEW_BOOKMARKS, Permission.VIEW_WALLETS];

	const displaySalesBarArray = [Permission.VIEW_AFFILIATES, Permission.VIEW_SALES_PEOPLE, Permission.VIEW_LEADS];

	const displayTradingOptionsArray = [Permission.MANAGE_MAX_RISK];

	const displaySettings = () => {
		return permissions?.find((p: Permission) => displaySettingsArray.includes(p));
	};

	const displayCustomersBar = () => {
		return permissions?.find((p: Permission) => displayCustomersBarArray.includes(p));
	};

	const displaySalesBar = () => {
		return permissions?.find((p: Permission) => displaySalesBarArray.includes(p));
	};

	const displayTradingOptions = () => {
		return permissions?.find((p: Permission) => displayTradingOptionsArray.includes(p));
	};

	const hasPermission = (permission: Permission) => {
		return permissions?.includes(permission);
	};

	const voipProviderQuery = useGetSetting('VOIP_PROVIDER', 'voip-provider-content', {
		enabled: hasPermission(Permission.MANAGE_GENERAL_SETTINGS),
	});
	const showVoipProvider =
		voipProviderQuery.data && voipProviderQuery.data !== '' && hasPermission(Permission.MANAGE_CALL_PROVIDERS);

	const emailProviderQuery = useGetSetting('EMAIL_PROVIDER', 'email-provider-content', {
		enabled: hasPermission(Permission.MANAGE_GENERAL_SETTINGS),
	});
	const showEmailProvider = emailProviderQuery.data !== '' && hasPermission(Permission.VIEW_EMAILS);

	const location = useLocation();
	const path = location.pathname;

	const resetDepositsFilter = () => {
		dispatch(clearDepositsFilters());
	};

	const resetWithdrawalFilters = () => {
		dispatch(clearWithdrawalFilters());
	};

	const resetTransactionsFilters = () => {
		dispatch(clearTransactionsFilters());
	};

	const resetCustomerFilters = () => {
		dispatch(clearCustomersFilters());
	};

	const onCustomersClicked = () => {
		resetCustomerFilters();
		if (path === '/customers') {
			history.replace('/customers');
		} else {
			history.push('/customers');
		}
	};

	const onAllCustomersClicked = (e: any) => {
		resetCustomerFilters();
		e.stopPropagation();
	};

	const onPositionsClicked = () => {
		dispatch(clearPositionsFilters());
		if (path === '/positions') {
			history.replace('/positions');
		} else {
			history.push('/positions');
		}
	};

	const stopPropagation = (e: any) => {
		e.stopPropagation();
	};

	return (
		<CSidebarNav>
			{hasPermission(Permission.VIEW_DASHBOARD) && (
				<CSidebarNavItem name={capitalize(_t('sidebar.overview'))} icon="overview" to="/" replace={path === '/'} />
			)}
			{displayCustomersBar() && (
				<CSidebarNavDropdown icon="referrals" onClick={onCustomersClicked} name={_t('sidebar.customers')}>
					{hasPermission(Permission.VIEW_CUSTOMERS) && (
						<CSidebarNavItem
							name={_t('global.all')}
							onClick={onAllCustomersClicked}
							to="/customers"
							replace={path === '/customers'}
						/>
					)}
					{hasPermission(Permission.VIEW_BOOKMARKS) && (
						<CSidebarNavItem
							name={_t('sidebar.bookmarked-users')}
							onClick={stopPropagation}
							to="/bookmarks"
							replace={path === '/bookmarks'}
						/>
					)}
					{hasPermission(Permission.VIEW_WALLETS) && (
						<CSidebarNavItem
							name={_t('wallets.title')}
							onClick={stopPropagation}
							to="/wallets"
							replace={path === '/wallets'}
						/>
					)}
				</CSidebarNavDropdown>
			)}
			{displaySalesBar() && (
				<CSidebarNavDropdown icon="cilBriefcase" onClick={onCustomersClicked} name={_t('sidebar.sales')}>
					{hasPermission(Permission.VIEW_AFFILIATES) && (
						<CSidebarNavItem
							name={_t('affiliates.title')}
							onClick={stopPropagation}
							to="/affiliates"
							replace={path === '/affiliates'}
						/>
					)}
					{hasPermission(Permission.VIEW_LEADS) && (
						<CSidebarNavItem
							name={_t('leads.title')}
							onClick={stopPropagation}
							to="/leads"
							replace={path === '/leads'}
						/>
					)}
					{hasPermission(Permission.VIEW_SALES_PEOPLE) && (
						<CSidebarNavItem
							name={_t('sidebar.sales-agents')}
							onClick={stopPropagation}
							to="/sales-people"
							replace={path === '/sales-people'}
						/>
					)}
				</CSidebarNavDropdown>
			)}
			{(hasPermission(Permission.VIEW_RISK) || hasPermission(Permission.VIEW_REPORTS)) && (
				<CSidebarNavDropdown icon="cil-graph" name={_t('sidebar.risk-management')}>
					{hasPermission(Permission.VIEW_RISK) && (
						<CSidebarNavItem
							name={_t('sidebar.risk-management-dashboard')}
							to="/risk_management"
							replace={path === '/risk_management'}
						/>
					)}
					{process.env.REACT_APP_STANDALONE && hasPermission(Permission.VIEW_REPORTS) && (
						<CSidebarNavItem
							name={_t('sidebar.reports')}
							to="/risk_management/reports"
							replace={path === '/risk_management/reports'}
						/>
					)}
				</CSidebarNavDropdown>
			)}

			{hasPermission(Permission.VIEW_TRANSACTIONS) && (
				<CSidebarNavDropdown icon="payments" name={_t('sidebar.transactions')}>
					<CSidebarNavItem
						name={_t('transactions.all-transactions')}
						to="/transactions"
						onClick={resetTransactionsFilters}
						replace={path === '/transactions'}
					/>
					<CSidebarNavItem
						name={_t('transactions.deposits')}
						to="/transactions/deposits"
						onClick={resetDepositsFilter}
						replace={path === '/transactions/deposits'}
					/>
					<CSidebarNavItem
						name={_t('transactions.withdrawals')}
						to="/transactions/withdrawals"
						onClick={resetWithdrawalFilters}
						replace={path === '/transactions/withdrawals'}
					/>
				</CSidebarNavDropdown>
			)}

			{hasPermission(Permission.VIEW_COINS) && (
				<CSidebarNavDropdown icon="coins" name={_t('sidebar.coins')}>
					<CSidebarNavItem name={_t('sidebar.transactions')} to="/coins" replace={path === '/coins'} />
					<CSidebarNavItem name={_t('sidebar.conversions')} to="/conversions" replace={path === '/conversions'} />
					{hasPermission(Permission.MANAGE_COINS) && (
						<CSidebarNavItem
							name={_t('sidebar.coins-settings')}
							to="/coins_settings"
							replace={path === '/coins_settings'}
						/>
					)}
				</CSidebarNavDropdown>
			)}

			{hasPermission(Permission.VIEW_POSITIONS) && (
				<CSidebarNavDropdown
					onClick={onPositionsClicked}
					route="/positions"
					icon="positions"
					name={_t('sidebar.trading')}
				>
					<CSidebarNavItem
						name={_t('sidebar.all-positions')}
						to="/positions"
						onClick={stopPropagation}
						replace={path === '/positions'}
					/>
					<CSidebarNavItem
						name={_t('sidebar.open-positions')}
						to="/positions/open"
						onClick={stopPropagation}
						replace={path === '/positions/open'}
					/>
					<CSidebarNavItem
						name={_t('sidebar.orders')}
						to="/orders"
						onClick={stopPropagation}
						replace={path === '/orders'}
					/>
				</CSidebarNavDropdown>
			)}

			{hasPermission(Permission.VIEW_COMPETITIONS) && (
				<CSidebarNavItem
					name={_t('sidebar.competitions')}
					icon="competition"
					to="/competitions"
					replace={path === '/competitions'}
				/>
			)}

			{hasPermission(Permission.VIEW_MASS_BONUSES) && (
				<CSidebarNavItem
					name={_t.translate('sidebar.mass-bonuses')}
					icon="bonuses"
					to="/mass-bonuses"
					replace={path === '/mass-bonuses'}
					badge={{ text: 'BETA', color: 'primary' }}
				/>
			)}

			{hasPermission(Permission.MANAGE_MESSAGES) && (
				<CSidebarNavItem
					name={_t.translate('sidebar.messaging')}
					icon="cil-mail"
					to="/messaging"
					replace={path === '/messaging'}
				/>
			)}

			{hasPermission(Permission.MANAGE_COMMISSION_MODELS) && (
				<CSidebarNavDropdown icon="cilBalanceScale" name={_t('commissions.commissions')}>
					<CSidebarNavItem
						name={_t('commissions.commission-models')}
						to="/commission_models"
						replace={path === '/commission_models'}
					/>
					<CSidebarNavItem
						name={_t('commissions.payout-requests')}
						to="/payout-requests"
						replace={path === '/payout-requests'}
					/>
					<CSidebarNavItem
						name={_t('commissions.ledger-records')}
						to="/commission-ledgers"
						replace={path === '/commission-ledgers'}
					/>
				</CSidebarNavDropdown>
			)}

			{hasPermission(Permission.MANAGE_PROP_CHALLENGES) && (
				<CSidebarNavDropdown icon="cilChart1" name={_t('prop-challenges.prop-trading-sidebar-name')}>
					<CSidebarNavItem
						name={_t('prop-challenges.challenges')}
						to="/prop-challenges"
						replace={path === '/prop-challenges'}
					/>
					{hasPermission(Permission.MANAGE_FUNDED_ACCOUNT_TYPES) && (
						<CSidebarNavItem
							name={_t('funded-account-types.types')}
							to="/funded-account-types"
							replace={path === '/funded-account-types'}
						/>
					)}
					{hasPermission(Permission.MANAGE_PROP_FUNNELS) && (
						<CSidebarNavItem name={_t('prop-funnels.funnels')} to="/prop-funnels" replace={path === '/prop-funnels'} />
					)}
				</CSidebarNavDropdown>
			)}

			{hasPermission(Permission.VIEW_AUDIT_LOGS) && (
				<CSidebarNavItem name={_t('audit-logs.title')} icon="cilFindInPage" to="/logs" replace={path === '/logs'} />
			)}

			{displaySettings() && (
				<CSidebarNavDropdown icon="cilSettings" name={_t('sidebar.settings')}>
					{hasPermission(Permission.MANAGE_GENERAL_SETTINGS) && (
						<CSidebarNavItem
							name={_t('sidebar.general-settings')}
							to="/general_settings"
							icon="cilSettings2"
							replace={path === 'general_settings'}
						/>
					)}

					{showVoipProvider && (
						<CSidebarNavItem
							name="VoIP providers"
							to="/voip-providers"
							icon="voip_provider"
							replace={path === 'voip-providers'}
						/>
					)}

					{process.env.REACT_APP_STANDALONE && hasPermission(Permission.VIEW_INSTRUMENTS) && (
						<CSidebarNavItem
							name={_t('instruments.title')}
							icon="instrument"
							to="/instruments"
							replace={path === '/instruments'}
						/>
					)}

					{hasPermission(Permission.VIEW_GROUPS) && (
						<CSidebarNavItem name={_t('sidebar.groups')} icon="groups" to="/groups" replace={path === '/groups'} />
					)}

					{showEmailProvider && (
						<CSidebarNavItem
							name={capitalize(_t('sidebar.emails'))}
							icon="email2"
							to="/emails"
							replace={path === '/emails'}
						/>
					)}

					{hasPermission(Permission.VIEW_CUSTOMER_FIELD_CONFIGURATIONS) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.onboarding')}
							icon="cilClipboard"
							to="/onboarding"
							replace={path === '/onboarding'}
						/>
					)}

					{hasPermission(Permission.VIEW_BONUSES) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.bonuses')}
							icon="cilGift1"
							to="/bonuses"
							replace={path === '/bonuses'}
						/>
					)}

					{hasPermission(Permission.VIEW_COUNTRIES) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.countries')}
							icon="cilGlobe1"
							to="/countries"
							replace={path === '/countries'}
						/>
					)}

					{hasPermission(Permission.VIEW_BANNERS) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.banners')}
							icon="cilWallpaper1"
							to="/banners"
							replace={path === '/banners'}
						/>
					)}

					{hasPermission(Permission.VIEW_DOWNTIMES) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.downtimes')}
							icon="cilClock1"
							to="/downtimes"
							replace={path === '/downtimes'}
						/>
					)}

					{hasPermission(Permission.VIEW_PAYMENT_PROVIDERS) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.payment-providers')}
							icon="cilCash1"
							to="/payment-providers"
							replace={path === '/payment-providers'}
						/>
					)}

					{hasPermission(Permission.VIEW_SALES_DESK) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.sales-desks')}
							icon="cilChartline1"
							to="/sales-desks"
							replace={path === '/sales-desks'}
						/>
					)}

					{hasPermission(Permission.MANAGE_SALES_STATUS) && (
						<CSidebarNavItem
							name={_t.translate('sidebar.sales-status')}
							to="/sales-status"
							icon="cilBalancescale1"
							replace={path === '/sales-status'}
						/>
					)}

					{hasPermission(Permission.VIEW_EXCHANGE_RATE) && (
						<CSidebarNavItem
							name={_t('exchangeRates.title')}
							to="/exchange-rate"
							icon="cilExchangeRates1"
							replace={path === '/exchange-rate'}
						/>
					)}

					{hasPermission(Permission.MANAGE_CURRENCY_CONVERSIONS) && (
						<CSidebarNavItem
							name={_t('currencyConversions.title')}
							to="/currency-conversion"
							icon="cilCurrencyConversion1"
							replace={path === '/currency-conversion'}
						/>
					)}

					{hasPermission(Permission.VIEW_ADAPTERS) && (
						<CSidebarNavItem
							name={_t('adapters.title')}
							icon="onboarding"
							to="/adapters"
							replace={path === '/adapters'}
						/>
					)}

					{displayTradingOptions() && (
						<CSidebarNavItem
							name={_t('sidebar.trading-options')}
							to="/trading-options"
							replace={path === '/trading-options'}
							icon="cilSwap1"
						/>
					)}
				</CSidebarNavDropdown>
			)}
		</CSidebarNav>
	);
};

const Sidebar = () => {
	const sidebarShown = useAppSelector((state) => state.core.sidebarShown);
	const dispatch = useAppDispatch();

	const handleSidebarShowChange = (shown: boolean | string) => dispatch(setSidebarShown(shown));

	return (
		<CSidebar show={sidebarShown} onShowChange={handleSidebarShowChange}>
			<CSidebarBrand className="d-md-down-none" to="/">
				<CImg src={logo} className="c-sidebar-brand-full" height={35} />
				<CImg src={logo} className="c-sidebar-brand-minimized" height={35} />
			</CSidebarBrand>
			<MainNavigation />
		</CSidebar>
	);
};

export default Sidebar;
